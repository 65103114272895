module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.strepsils.hu/","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../gatsby-source-umbraco-phoenix/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix/.gatsby","rootPath":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix","xOrigin":["https://phx-strepsils-hu-prod.husky-2.rbcloud.io/"],"baseURL":"https://phx-strepsils-hu-prod.husky-2.rbcloud.io/","siteURL":"https://phx-strepsils-hu-prod.husky-2.rbcloud.io/","params":{"salsify":{"url":"https://app.salsify.com","orgId":"s-3ec6a81f-e07f-4078-b4cb-1a7206b985e9","authToken":"4e1d3280bdd37e291b0cbe28fe34f56f33d6da9b7a4d74d77562a72cb49a65a1"}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-husky/static/images/Strepsils-favicon-red.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"716092c4822b7a5814c389bc7a49664a"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T58S78","includeInDevelopment":true,"timeout":6000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-theme-husky/.gatsby","projectRoot":"/home/vsts/work/1/s/packages/gatsby-theme-husky","babel":false,"tsNode":true},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
